import React from 'react';
import { Link } from 'gatsby';
import { css, Styled } from 'theme-ui';
import Header from 'gatsby-theme-blog/src/components/header';

export default props => (
  <Header {...props}>
    <Styled.a
      as={Link}
      to="/writing"
      css={css({
        ml: 2,
        mr: `auto`,
        fontFamily: `heading`,
        fontWeight: `bold`,
        textDecoration: `none`,
        color: `inherit`,
        ':hover': {
          textDecoration: `underline`,
        },
      })}
    >
      writing
    </Styled.a>
    <Styled.a
      as={Link}
      to="/taking/notes"
      css={css({
        ml: 2,
        mr: `auto`,
        fontFamily: `heading`,
        fontWeight: `bold`,
        textDecoration: `none`,
        color: `inherit`,
        ':hover': {
          textDecoration: `underline`,
        },
      })}
    >
      taking notes
    </Styled.a>
  </Header>
);
